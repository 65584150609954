import { useState, useEffect } from "react";
import DashboardHomeBox from "./DashboardHomeBox";
import { fetchWithrawalRequest } from "../../../api/apiService";

// const DashboardHome = () => {
// const [totalWithdrawalRequests, setTotalWithdrawalRequests] = useState([]);
// // Fetch data
// useEffect(() => {
//   const fetchWithdrawalInfo = async () => {
//     try {
//       const response = await fetchWithrawalRequest();
//       const totalRequest = response.totalWithdrawalsRequest;

//       setTotalWithdrawalRequests(totalRequest);
//     } catch (error) {
//       console.error("Error fetching withdrawal requests:", error);
//     }
//   };

//   fetchWithdrawalInfo();
// }, []);

//   return (
//     <section className="py-7 px-4">
//       <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
//         <DashboardHomeBox
//           heading="Total Users"
//           total="0"
//           borderColor="border-[#f97729]"
//           color="text-[#f97729]"
//         />
//         <DashboardHomeBox
//           heading="Total Deposit"
//           total="0"
//           borderColor="border-[#045544]"
//           color="text-[#045544]"
//         />
//         <DashboardHomeBox
//           heading="Total Withdrawal"
//           total="0"
//           borderColor="border-[#f97729]"
//           color="text-[#f97729]"
//         />
//         <DashboardHomeBox
//           heading="Total paid creators"
//           total="0"
//           borderColor="border-[#f97729]"
//           color="text-[#f97729]"
//         />
//         <DashboardHomeBox
//           heading="Total transaction fees"
//           total="0"
//           borderColor="border-[#045544]"
//           color="text-[#045544]"
//         />
//         <DashboardHomeBox
//           heading="Total daily users"
//           total="0"
//           borderColor="border-[#f97729]"
//           color="text-[#f97729]"
//         />
//         <DashboardHomeBox
//           heading="Total withdrawal request"
//           total={totalWithdrawalRequests}
//           borderColor="border-[#f97729]"
//           color="text-[#f97729]"
//         />
//         <DashboardHomeBox
//           heading="Total point claimed"
//           total="0"
//           borderColor="border-[#f97729]"
//           color="text-[#f97729]"
//         />
//         <DashboardHomeBox
//           heading="Total subscription"
//           total="0"
//           borderColor="border-[#045544]"
//           color="text-[#045544]"
//         />
//       </div>
//     </section>
//   );
// };

// export default DashboardHome;

const Marketplace = () => {
  const [metrics] = useState({
    totalPosts: 156,
    totalLikes: 4328,
    totalComments: 892,
    activeTasks: 12,
  });
  const [recentActivity] = useState([
    {
      id: 1,
      type: "like",
      user: "alex_travels",
      content: "liked your post",
      time: "2m ago",
    },
    {
      id: 2,
      type: "comment",
      user: "foodie_jen",
      content: "commented on your photo",
      time: "5m ago",
    },
    {
      id: 3,
      type: "task",
      user: "mike_photos",
      content: "completed your task",
      time: "15m ago",
    },
    {
      id: 4,
      type: "follow",
      user: "nature_sam",
      content: "started following you",
      time: "1h ago",
    },
  ]);
  const [topPosts, setTopPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalWithdrawalRequests, setTotalWithdrawalRequests] = useState([]);

  // Fetch data
  useEffect(() => {
    const fetchWithdrawalInfo = async () => {
      try {
        const response = await fetchWithrawalRequest();
        const totalRequest = response.totalWithdrawalsRequest;

        setTotalWithdrawalRequests(totalRequest);
      } catch (error) {
        console.error("Error fetching withdrawal requests:", error);
      }
    };

    fetchWithdrawalInfo();
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
          "https://starfaceapi.site/api/post/get-posts?page=1&limit=50"
        );
        const data = await response.json();

        if (data?.posts) {
          // Set totalPosts from the response
          setTotalPosts(data.totalPosts);

          // Sort posts by (likes + comments) in descending order
          const sortedPosts = data.posts.sort(
            (a, b) =>
              b.likes + b.comments.length - (a.likes + a.comments.length)
          );

          // Take the top 20 posts
          const top20Posts = sortedPosts.slice(0, 5).map((post) => ({
            id: post._id,
            image: post.file[0]?.thumbnailUrl || "/default-image.jpg",
            likes: post.likes,
            comments: post.comments.length,
          }));

          setTopPosts(top20Posts);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await fetch(
          "https://starfaceapi.site/api/user/search-user?query=john&page=1&limit=2"
        );
        const data = await response.json();

        if (data?.totalUsers) {
          setTotalUsers(data.totalUsers); // Set totalUsers from the response
        }
      } catch (error) {
        console.error("Error fetching total users:", error);
      }
    };

    fetchTotalUsers();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <div className="flex-1">
        <div className="bg-white border-b shadow-sm">
          <div className="max-w-7xl mx-auto px-4 py-4">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-bold text-gray-800">Dashboard</h1>
              <div className="flex items-center gap-4">
                <button className="p-2 text-gray-500 hover:text-gray-700">
                  <i className="fas fa-bell"></i>
                </button>
                <button className="p-2 text-gray-500 hover:text-gray-700">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600">Total Posts</p>
                  <h3 className="text-2xl font-bold">{totalPosts}</h3>
                </div>
                <i className="fas fa-image text-2xl text-blue-500"></i>
              </div>
            </div>
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600">Total Users</p>
                  <h3 className="text-2xl font-bold">{totalUsers}</h3>
                </div>
                <i className="fas fa-users text-2xl text-blue-500"></i>
              </div>
            </div>
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600">Total Withdrawal Request</p>
                  <h3 className="text-2xl font-bold">
                    {totalWithdrawalRequests}
                  </h3>
                </div>
                <i className="fas fa-comment text-2xl text-green-500"></i>
              </div>
            </div>
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600">Active Tasks</p>
                  <h3 className="text-2xl font-bold">{metrics.activeTasks}</h3>
                </div>
                <i className="fas fa-tasks text-2xl text-purple-500"></i>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white rounded-lg p-6 shadow-sm">
                <h2 className="text-lg font-semibold mb-4">Recent Activity</h2>
                <div className="space-y-4">
                  {recentActivity.map((activity) => (
                    <div
                      key={activity.id}
                      className="flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg"
                    >
                      <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                        <i
                          className={`fas fa-${
                            activity.type === "like"
                              ? "heart"
                              : activity.type === "comment"
                              ? "comment"
                              : activity.type === "task"
                              ? "check"
                              : "user"
                          } text-gray-600`}
                        ></i>
                      </div>
                      <div className="flex-1">
                        <p className="text-sm">
                          <span className="font-semibold">{activity.user}</span>{" "}
                          {activity.content}
                        </p>
                        <span className="text-xs text-gray-500">
                          {activity.time}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-white rounded-lg p-6 shadow-sm">
                <h2 className="text-lg font-semibold mb-4">
                  Engagement Trends
                </h2>
                <div className="h-[200px] flex items-end justify-between gap-2">
                  {[65, 45, 75, 50, 80, 60, 70].map((height, index) => (
                    <div
                      key={index}
                      className="w-full bg-blue-100 rounded-t-lg hover:bg-blue-200 transition-colors"
                      style={{ height: `${height}%` }}
                    ></div>
                  ))}
                </div>
                <div className="flex justify-between mt-2 text-sm text-gray-500">
                  <span>Mon</span>
                  <span>Tue</span>
                  <span>Wed</span>
                  <span>Thu</span>
                  <span>Fri</span>
                  <span>Sat</span>
                  <span>Sun</span>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <h2 className="text-lg font-semibold mb-4">
                Top Performing Posts
              </h2>
              <div className="grid grid-cols-1 gap-4">
                {topPosts.map((post) => (
                  <div
                    key={post.id}
                    className="flex items-center gap-4 hover:bg-gray-50 p-2 rounded-lg"
                  >
                    <img
                      src={post.image}
                      alt="Post thumbnail"
                      className="w-16 h-16 object-cover rounded-lg"
                    />
                    <div>
                      <div className="flex gap-4 text-sm text-gray-600">
                        <span className="flex items-center gap-1">
                          <i className="fas fa-heart text-red-500"></i>
                          {post.likes}
                        </span>
                        <span className="flex items-center gap-1">
                          <i className="fas fa-comment text-blue-500"></i>
                          {post.comments}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
