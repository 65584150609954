import axios from "axios";

// Base configuration for all API requests
const apiClient = axios.create({
  baseURL: "https://starfaceapi.site/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor for adding the token if available
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Fetch Withdrawal Request
export const fetchWithrawalRequest = async (status) => {
  const response = await apiClient.get(
    `/transaction/withdrawal-request?status=${status}`
  );
  return response.data;
};

// Approve withdrawal request
export const approveWithdrawalRequest = async (requestId) => {
  const response = await apiClient.post("/trans/approve-withdrawal", {
    requestId,
  });
  return response.data;
};

export const rejectWithdrawalRequest = async (requestId) => {
  const response = await apiClient.post("/trans/reject-withdrawal", {
    requestId,
  });
  return response.data;
};

// export const rejectWithdrawalRequest = async (body) => {
//   const response = await fetch(
//     "https://starfaceapi.site/api/trans/reject-withdrawal",
//     {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(body),
//     }
//   );
//   return response.json();
// };

// Fetch Withdrawal Request
export const fetchTransactHistory = async (type, startDate, endDate) => {
  const params = new URLSearchParams();
  if (type) params.append("type", type);
  if (startDate) params.append("startDate", startDate);
  if (endDate) params.append("endDate", endDate);

  const response = await apiClient.get(
    `/transaction/all-history?${params.toString()}`
  );
  return response.data;
};

export const withdralFromUsers = async (status) => {
  const response = await apiClient.post(`/trans/withdraw-from-user`);
  return response.data;
};

// Fetch wallet details
export const fetchWalletDetails = async (status) => {
  const response = await apiClient.get(`/wallet/get-wallet`);
  return response.data;
};

// Request OTP endpoint
export const requestOTP = async (email, referralCode) => {
  const payload = { email };
  if (referralCode) {
    payload.referralCode = referralCode;
  }
  const response = await apiClient.post("/auths/request-otp", payload);
  return response.data;
};

// Verify OTP endpoint
export const verifyOTP = async (userId, otp) => {
  const response = await apiClient.post("/auths/verify-otp", {
    userId,
    otp,
  });
  return response.data;
};

// Resend OTP endpoint
export const setUserPassword = async (email, password) => {
  const response = await apiClient.post("/auths/set-password", {
    email,
    password,
  });
  return response.data;
};

// Login endpoint
export const login = async (email, password) => {
  const response = await apiClient.post("/auths/signin", { email, password });
  return response.data; // Return response data
};

// Fetch user profile
export const fetchUserProfile = async () => {
  const response = await apiClient.get("/user/profile");
  return response.data;
};

// Transfer endpoint
export const transferFunds = async (fromWallet, toWallet, amount) => {
  const response = await apiClient.post("/wallets/transfer", {
    fromWallet,
    toWallet,
    amount,
  });
  return response.data;
};

export const redeemStar = async () => {
  try {
    const response = await apiClient.get("/transaction/redeem-stars");
    return response.data;
  } catch (error) {
    console.error(
      "Error redeeming stars:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const getBalance = async () => {
  try {
    const response = await apiClient.get("/earn/wallet");
    return response.data;
  } catch (error) {
    console.error(
      "Error getting balance",
      error.response?.data || error.message
    );
    throw error;
  }
};

// Fetch wallet details
export const fetchUsersWalletDetails = async (queryParams) => {
  const response = await apiClient.get(
    `/wallet/getusers-wallet?${queryParams}`
  );
  return response.data;
};
export const GetUsers = async () => {
  try {
    const response = await apiClient.get("/connect");
    return response.data;
  } catch (error) {
    console.error(
      "Error getting balance",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const GetPendingTasks = async (page) => {
  try {
    const response = await apiClient.get(
      `/marketPlace/all-taskproofs?page=${page}&limit=5&status=Pending`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting Tasks", error.response?.data || error.message);
    throw error;
  }
};

export const getMovies = async (page = 1, limit = 10) => {
  try {
    const response = await apiClient.get(
      `/movie/get-movies?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching movies:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const uploadMovies = async (formData) => {
  try {
    const response = await apiClient.post(`/movie/post-movie`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error uploading movie:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const deleteMovie = async (movieId) => {
  try {
    const response = await apiClient.delete(`/movie/delete/${movieId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting movie:",
      error.response?.data || error.message
    );
    throw error;
  }
};
