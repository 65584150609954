import React, { useEffect, useRef, useState } from "react";
// import Hls from "hls.js";

const MoviePlayer = ({ src, thumbnail, isMuted }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Initialize HLS.js if supported
    // if (Hls.isSupported()) {
    //   const hls = new Hls();
    //   hls.loadSource(src);
    //   hls.attachMedia(videoRef.current);

    //   return () => hls.destroy();
    // } else
    if (
      videoRef.current &&
      videoRef.current.canPlayType("application/vnd.apple.mpegurl")
    ) {
      videoRef.current.src = src;
    }
  }, [src]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted]);

  // Play/pause toggle
  const togglePlayPause = () => {
    const videoElement = videoRef.current;

    if (videoElement) {
      if (isPlaying) {
        videoElement.pause();
      } else {
        videoElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="relative">
      <video
        ref={videoRef}
        loop
        playsInline
        poster={thumbnail}
        className="w-full object-cover rounded-lg"
        onClick={togglePlayPause}
      />
      {/* Play/Pause Button */}
      <button
        onClick={togglePlayPause}
        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-3xl rounded-lg"
      >
        <i className={`fas ${isPlaying ? "fa-pause" : "fa-play"}`} />
      </button>
      {/* Mute/Unmute Button */}
      <button
        onClick={() => {
          if (videoRef.current) {
            videoRef.current.muted = !videoRef.current.muted;
          }
        }}
        className="absolute top-2 right-2 bg-gray-800 bg-opacity-75 text-white px-2 py-1 rounded-md text-sm flex items-center"
      >
        <i className={`fas ${isMuted ? "fa-volume-mute" : "fa-volume-up"}`} />
      </button>
    </div>
  );
};

export default MoviePlayer;
