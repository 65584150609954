import React, { useEffect, useState } from "react";
import {
  fetchUsersWalletDetails,
  fetchWalletDetails,
  withdralFromUsers,
} from "../../../api/apiService";
import ProtectedRoute from "../../Authenticated/ProtectedRoute";

const WalletDetails = () => {
  const [walletDetails, setWalletDetails] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [currencyFilter, setCurrencyFilter] = useState("");
  const [balanceFilter, setBalanceFilter] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [totalBalances, setTotalBalances] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  // Fetch wallet details
  const fetchWalletDetail = async () => {
    try {
      const response = await fetchWalletDetails();
      console.log("API Response:", response);

      // Assuming response is an array of wallet details
      if (Array.isArray(response)) {
        setWalletDetails(response);
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error fetching wallet details:", error);
    }
  };

  const withdralFromUser = async () => {
    try {
      const response = await withdralFromUsers();
      console.log("API Response:", response);
      alert(`${response.message}`);
    } catch (error) {
      console.error("Error making withdrawal:", error);
    }
  };

  const fetchFilteredWallets = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: currentPage,
        limit: 10,
        currency: currencyFilter,
        balance: balanceFilter,
      });

      const response = await fetchUsersWalletDetails(queryParams.toString());
      console.log("API Response:", response);

      const walletList = response.wallets;

      if (!Array.isArray(walletList)) {
        console.error("Unexpected response structure:", response);
        return;
      }

      setWalletDetails(walletList);

      // Extract unique currencies
      const uniqueCurrencies = [
        ...new Set(walletList.map((wallet) => wallet.currency)),
      ];
      setCurrencies(uniqueCurrencies);

      // Update total pages from response (assumes API provides total pages)
      if (response.totalPages) {
        setTotalPages(response.totalPages);
      }

      // Calculate total balances grouped by currency
      const balanceByCurrency = walletList.reduce((acc, wallet) => {
        const { currency, bbalance } = wallet;
        acc[currency] = (acc[currency] || 0) + bbalance;
        return acc;
      }, {});
      setTotalBalances(balanceByCurrency);
    } catch (error) {
      console.error("Error fetching wallet details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Helpers to get balance or address for a specific currency
  const getBalanceByCurrency = (currency) => {
    const wallet = walletDetails.find((wallet) => wallet.currency === currency);
    return wallet ? wallet.balance : "N/A";
  };

  const getAddressByCurrency = (currency) => {
    const wallet = walletDetails.find((wallet) => wallet.currency === currency);
    return wallet ? wallet.address : "N/A";
  };

  // Copy address to clipboard
  const copyToClipboard = (address) => {
    if (!address || address === "N/A") {
      alert("Address not available!");
      return;
    }
    navigator.clipboard.writeText(address).then(
      () => {
        alert("Address copied to clipboard!");
      },
      (err) => {
        alert("Failed to copy address.");
        console.error("Copy failed:", err);
      }
    );
  };

  useEffect(() => {
    fetchWalletDetail();
  }, []);

  useEffect(() => {
    fetchFilteredWallets();
  }, [currencyFilter, balanceFilter, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <section className="relative pt-7 px-3 sm:px-8">
      {/* Wallet Overview Section */}
      <div className="flex justify-between items-center px-4 sm:px-10">
        <p className="text-sm sm:text-base">
          SFC Balance: {getBalanceByCurrency("SFC")}
        </p>
        <p className="text-sm sm:text-base">
          USDT Balance: {getBalanceByCurrency("USDT")}
        </p>
        <p className="text-sm sm:text-base">
          BNB Balance: {getBalanceByCurrency("BNB")}
        </p>

        <ProtectedRoute>
          <button
            onClick={withdralFromUser}
            className="py-1 px-3 bg-[#f97729] text-white rounded text-sm sm:text-base hover:bg-[#e06b25]"
          >
            Withdraw users balance
          </button>
        </ProtectedRoute>

        <button
          className="flex items-center space-x-1 text-sm sm:text-base"
          onClick={() => copyToClipboard(getAddressByCurrency("USDT"))}
        >
          <p>
            Wallet Address:{" "}
            <span className="text-gray-500">
              {getAddressByCurrency("USDT").replace(
                /^(.{4}).+(.{4})$/,
                "$1****$2"
              )}
            </span>
          </p>
        </button>
      </div>

      {/* Filter Section */}
      <div className="mb-4 flex flex-wrap space-y-2 sm:space-y-0 sm:space-x-4">
        <div>
          <label htmlFor="currencyFilter" className="mr-2 text-lg">
            Currency:
          </label>
          <select
            id="currencyFilter"
            value={currencyFilter}
            onChange={(e) => setCurrencyFilter(e.target.value)}
            className="py-1 px-2 border rounded"
          >
            <option value="">All</option> {/* Option to show all wallets */}
            {currencies.map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="balanceFilter" className="mr-2 text-lg">
            Balance:
          </label>
          <input
            type="number"
            id="balanceFilter"
            value={balanceFilter}
            onChange={(e) => setBalanceFilter(e.target.value)}
            className="py-1 px-2 border rounded"
            placeholder="Enter balance"
          />
        </div>
        <button
          onClick={fetchFilteredWallets}
          className="py-1 px-3 bg-[#f97729] text-white rounded shadow-md hover:bg-[#e06b25]"
          disabled={loading} // Disable the button while loading
        >
          {loading ? "Loading..." : "Apply Filters"}
        </button>
      </div>

      {/* Total Balances Section */}
      <div className="mt-6">
        <h4 className="text-lg font-semibold mb-4">
          Total Balances by Currency:
        </h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4">
          {Object.entries(totalBalances).map(([currency, total]) => (
            <div
              key={currency}
              className="p-4 border rounded-lg shadow-md bg-white text-center"
            >
              <h5 className="text-xl font-bold text-[#f97729]">{currency}</h5>
              <p className="text-lg font-medium mt-2">
                {total.toLocaleString()}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Wallet Details Section */}
      <div className="bg-white shadow-md overflow-x-auto mt-6">
        {loading ? (
          <div className="py-4 text-center">
            <span className="text-lg font-semibold text-gray-700">
              Loading wallet details...
            </span>
          </div>
        ) : (
          <>
            <h4 className="w-screen py-2 text-white text-xl text-center bg-[#f97729] rounded-t sm:w-full">
              Wallet Details
            </h4>
            <table className="w-screen text-center bg-white rounded sm:w-full">
              <thead>
                <tr className="text-lg border-b-2">
                  <th className="px-4 py-2">User ID</th>
                  <th className="px-4 py-2">Currency</th>
                  <th className="px-4 py-2">Address</th>
                  <th className="px-4 py-2">Balance</th>
                  <th className="px-4 py-2">Contract Address</th>
                </tr>
              </thead>
              <tbody>
                {walletDetails.map((wallet) => (
                  <tr key={wallet.address} className="border-b">
                    <td className="py-2 px-4">{wallet.userId}</td>
                    <td className="py-2 px-4">{wallet.currency}</td>
                    <td className="py-2 px-4">{wallet.address}</td>
                    <td className="py-2 px-4">{wallet.bbalance}</td>
                    <td className="py-2 px-4">{wallet.contractAddress}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>

      {/* Pagination Section */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1 || loading}
          className={`py-1 px-3 rounded shadow-md ${
            currentPage === 1 || loading
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-[#f97729] text-white hover:bg-[#e06b25]"
          }`}
        >
          Previous
        </button>
        <span className="text-lg">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages || loading}
          className={`py-1 px-3 rounded shadow-md ${
            currentPage === totalPages || loading
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-[#f97729] text-white hover:bg-[#e06b25]"
          }`}
        >
          Next
        </button>
      </div>
    </section>
  );
};

export default WalletDetails;
