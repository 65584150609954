import { useState, useEffect } from "react";
import {
  fetchWithrawalRequest,
  rejectWithdrawalRequest,
} from "../../../api/apiService";
import DashboardPatientsModal from "./AcceptRejectModal";
import DashboardOverlay from "../DashboardOverlay";

const DashboardPatients = () => {
  const [showModal, setShowModal] = useState(false);
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [statusFilter, setStatusFilter] = useState("pending"); // Default filter
  const [isProcessing, setIsProcessing] = useState(false); // To handle API call state

  const showModalHandler = (request = null) => {
    setSelectedRequest(request);
    setShowModal((prevVal) => !prevVal);
  };

  // Fetch data
  useEffect(() => {
    const fetchWithdrawalInfo = async () => {
      try {
        const response = await fetchWithrawalRequest();
        const requestList = response.WithdrawalsRequest;

        if (!response || !Array.isArray(requestList)) {
          console.error("Unexpected response structure:", response);
          return;
        }

        setWithdrawalRequests(requestList);
      } catch (error) {
        console.error("Error fetching withdrawal requests:", error);
      }
    };

    fetchWithdrawalInfo();
  }, []);

  // Filter data by status
  useEffect(() => {
    setFilteredRequests(
      withdrawalRequests.filter(
        (request) => request.withdrawalStatus === statusFilter
      )
    );
  }, [withdrawalRequests, statusFilter]);

  const handleReject = async (request) => {
    if (!request || !request._id) {
      console.error("Invalid request object:", request);
      alert("Failed to reject. Invalid request data.");
      return;
    }

    setIsProcessing(true);
    try {
      console.log("Rejecting withdrawal request with ID:", request._id);
      const response = await rejectWithdrawalRequest(request._id);
      if (response.status === 200) {
        alert("Withdrawal request rejected successfully!");
        // Update the UI
        setWithdrawalRequests((prevRequests) =>
          prevRequests.map((r) =>
            r._id === request._id ? { ...r, withdrawalStatus: "failed" } : r
          )
        );
      } else {
        alert(response.message || "Failed to reject withdrawal request.");
      }
    } catch (error) {
      console.error("Error rejecting withdrawal request:", error);
      alert("An error occurred while rejecting the request.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div>
      <section className="relative pt-7 px-3 sm:px-8">
        {/* Status Filter Dropdown */}
        <div className="mb-4">
          <label htmlFor="statusFilter" className="mr-2 text-lg">
            Filter by Status:
          </label>
          <select
            id="statusFilter"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="py-1 px-2 border rounded"
          >
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
            <option value="rejected">failed</option>
          </select>
        </div>

        <div className="bg-white shadow-md overflow-x-auto">
          <h4 className="w-screen py-2 text-white text-xl text-center bg-[#f97729] rounded-t sm:w-full">
            Withdrawal Request
          </h4>
          <table className="w-screen text-center bg-white rounded sm:w-full">
            <thead>
              <tr className="text-lg border-b-2">
                <th className="px-4 py-2">User ID</th>
                <th className="px-4 py-2">Amount</th>
                <th className="px-4 py-2">Wallet</th>
                <th className="px-4 py-2">Asset</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredRequests.map((request) => (
                <tr key={request._id} className="border-b">
                  <td className="py-2 px-4">{request.userId}</td>
                  <td className="py-2 px-4">{request.amount}</td>
                  <td className="py-2 px-4">{request.receiverWallet}</td>
                  <td className="py-2 px-4">{request.asset}</td>
                  <td className="py-2 px-4">{request.withdrawalStatus}</td>
                  <td className="flex justify-center space-x-2 py-2">
                    <button
                      className={`py-1 px-3 text-sm rounded shadow-md sm:text-base ${
                        request.withdrawalStatus === "completed"
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-[#045544] text-white hover:bg-[#038c66]"
                      }`}
                      onClick={() =>
                        request.withdrawalStatus !== "completed" &&
                        showModalHandler(request)
                      }
                      disabled={request.withdrawalStatus === "completed"}
                    >
                      Approve
                    </button>
                    <button
                      className={`py-1 px-3 text-sm rounded shadow-md sm:text-base ${
                        request.withdrawalStatus !== "pending"
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-[#f97729] text-white hover:bg-[#e06b25]"
                      }`}
                      onClick={() => {
                        console.log("Rejecting request:", request);
                        handleReject(request);
                      }}
                      disabled={
                        request.withdrawalStatus !== "pending" || isProcessing
                      }
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {showModal && (
          <div className="absolute z-20 top-4 left-1/2 -translate-x-1/2 md:top-6">
            <DashboardPatientsModal
              showModalHandler={showModalHandler}
              selectedRequest={selectedRequest}
            />
          </div>
        )}
      </section>

      {showModal && <DashboardOverlay />}
    </div>
  );
};

export default DashboardPatients;
