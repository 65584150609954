import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { navigation } from "../navigation";

const NavigationLayout = () => {
  const isAuthenticated = !!localStorage.getItem("token");
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login"); // Redirect to login page after logout
  };

  return (
    <div className="dashboard-layout">
      {isAuthenticated && (
        <nav className="navigation">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                `nav-link ${isActive ? "active" : ""}`
              }
            >
              <i className={`fas fa-${item.icon} w-5`}></i>
              {item.name}
            </NavLink>
          ))}
          {/* Logout Option */}
          <button onClick={handleLogout} className="nav-link logout-button">
            <i className="fas fa-sign-out-alt w-5"></i>
            Logout
          </button>
        </nav>
      )}
      <main className="content">
        <Outlet />
      </main>
    </div>
  );
};

export default NavigationLayout;
