import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import MoviePlayer from "./Player";
import { deleteMovie, getMovies } from "../../../api/apiService";
import { toast } from "react-toastify";

const Movie = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [globalMute, setGlobalMute] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchPosts = async (page = 1, clear = false) => {
    setIsLoading(true);
    try {
      const data = await getMovies(page);
      if (clear) {
        setMovies(data.movies); // Replace the list entirely
      } else {
        setMovies((prevPosts) => [...prevPosts, ...data.movies]); // Append for pagination
      }
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  const handleDelete = async (movieId) => {
    if (!movieId) {
      console.error("Invalid movieId:", movieId);
      toast.error("Could not delete movie: Invalid ID");
      return;
    }
    try {
      await deleteMovie(movieId);
      toast.success("Movie deleted successfully");

      // Refresh the list after deleting
      await fetchPosts(currentPage, true);
    } catch (error) {
      console.error("Could not delete movie:", error);
      toast.error("Movie could not be deleted");
    }
  };

  const handleSubmit = (data) => {
    console.log("Form submitted:", data);
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-gray-800 text-white p-6">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row gap-4 justify-between items-center">
          <h1 className="text-2xl font-bold">Movie Dashboard</h1>
          <form className="flex gap-2 w-full md:w-auto">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for movies..."
              className="px-4 py-2 rounded-md text-gray-800 w-full md:w-80"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-pink-500 text-white rounded-md hover:bg-pink-600 transition-colors"
            >
              <i className="fas fa-search"></i>
            </button>
          </form>
          <button
            onClick={() => setIsModalOpen(true)}
            className="px-4 py-2 bg-pink-500 text-white rounded-md hover:bg-pink-600 transition-colors flex items-center gap-2"
          >
            <i className="fas fa-plus"></i>
            Upload Movie
          </button>
        </div>
      </header>

      <main className="max-w-7xl mx-auto p-6">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="text-2xl text-gray-600">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          </div>
        ) : (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {movies.map((movie) => (
              <div
                key={movie._id}
                className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <div className="relative group">
                  <MoviePlayer
                    src={movie.videoUrl}
                    thumbnail={movie.thumbnailUrl}
                    isMuted={globalMute}
                    // toggleMute={() => setGlobalMute(!globalMute)}
                  />
                  <button
                    onClick={() => handleDelete(movie._id)}
                    className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:bg-red-600"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-lg text-gray-800 mb-2">
                    {movie.title}
                  </h3>
                  <div className="flex items-center justify-between text-gray-600">
                    <div className="flex items-center">
                      <i className="fas fa-star text-yellow-400 mr-1"></i>
                      <span>
                        {movie.rating ? movie.rating.toFixed(1) : "N/A"}
                      </span>
                    </div>
                    <div className="font-semibold text-green-600">
                      {movie.price}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default Movie;
