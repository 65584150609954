import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./fonts/Providence.ttf";

import ProtectedRoute from "./components/Authenticated/ProtectedRoute";
import NavigationLayout from "./components/NavigationLayout";

// Pages
import LoginPage from "./pages/Dashboard/Login";
import RegisterPage from "./pages/Dashboard/Register";
import DashboardHomePage from "./pages/Dashboard/DashboardHome";
import MarketPlace from "./components/Dashboard/MarketPlace/MarketPlace";
import WithdrawalRequestPage from "./components/Dashboard/WithdrawalRequest/WithdrawalRequest";
import TransactionHistoryPage from "./components/Dashboard/TransactionHistory/TransactionHistory";
import UsersWalletPage from "./components/Dashboard/UsersWallet/UsersWallet";
import Movie from "./components/Dashboard/Movie/Movie";

// Placeholder Components

const SettingsPage = () => <div>Settings Page</div>;

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/",
    element: <NavigationLayout />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <DashboardHomePage />
          </ProtectedRoute>
        ),
      },
      {
        path: "transaction-history",
        element: (
          <ProtectedRoute>
            <TransactionHistoryPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "marketplace",
        element: (
          <ProtectedRoute>
            <MarketPlace />
          </ProtectedRoute>
        ),
      },
      {
        path: "users-wallet",
        element: (
          <ProtectedRoute>
            <UsersWalletPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "movie",
        element: (
          <ProtectedRoute>
            <Movie />
          </ProtectedRoute>
        ),
      },
      {
        path: "withdrawal-request",
        element: (
          <ProtectedRoute>
            <WithdrawalRequestPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const App = () => {
  return (
    <>
      <ToastContainer
        className="custom-toast-container"
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <RouterProvider router={router} />
    </>
  );
};

export default App;
