export const navigation = [
  { name: "Dashboard", icon: "tachometer-alt", href: "/", page: "dashboard" },
  {
    name: "Transaction History",
    icon: "instagram",
    href: "/transaction-history",
    page: "transactionHistory",
  },
  {
    name: "Marketplace",
    icon: "store",
    href: "/marketplace",
    page: "marketplace",
  },
  {
    name: "Users Wallet",
    icon: "wallet",
    href: "/users-wallet",
    page: "usersWallet",
  },
  {
    name: "Movie",
    icon: "gallery",
    href: "/movie",
    page: "movie",
  },
  {
    name: "Withdrawal Requsest",
    icon: "chart-line",
    href: "/withdrawal-request",
    page: "withdrawalRequest",
  },
  { name: "Settings", icon: "cog", href: "/settings", page: "settings" },
];
