import React, { useState, useEffect } from "react";
import { GetPendingTasks } from "../../../api/apiService";

const Marketplace = () => {
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [metrics] = useState({
    totalPosts: 156,
    totalLikes: 4328,
    totalComments: 892,
    activeTasks: 12,
  });
  const [pendingTasks] = useState([
    {
      id: 1,
      user: "alex_travels",
      type: "Page Like",
      status: "pending",
      timestamp: "2h ago",
      url: "https://facebook.com/techreviews",
    },
    {
      id: 2,
      user: "foodie_jen",
      type: "Post Share",
      status: "pending",
      timestamp: "3h ago",
      url: "https://facebook.com/post/123",
    },
    {
      id: 3,
      user: "mike_photos",
      type: "Post Engagement",
      status: "pending",
      timestamp: "4h ago",
      url: "https://facebook.com/post/456",
    },
  ]);
  const ads = [
    { id: 1, title: "Ad Campaign 1", status: "Active" },
    { id: 2, title: "Ad Campaign 2", status: "Pending" },
  ];

  const fetchTasks = async (page) => {
    setIsLoading(true);
    try {
      const data = await GetPendingTasks(page);
      setTasks(data.taskProofs);
      setTotalPages(data.pages);
    } catch (error) {
      console.error("Failed to fetch pending tasks:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleViewTask = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTask(null);
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <div className="flex-1">
        <div className="bg-white border-b shadow-sm">
          <div className="max-w-7xl mx-auto px-4 py-4">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-bold text-gray-800">Dashboard</h1>
              <div className="flex items-center gap-4">
                <button className="p-2 text-gray-500 hover:text-gray-700">
                  <i className="fas fa-bell"></i>
                </button>
                <button className="p-2 text-gray-500 hover:text-gray-700">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
            {/* Total Ads */}
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600">Total Ads</p>
                  <h3 className="text-2xl font-bold">{metrics.totalAds}</h3>
                </div>
                <i className="fas fa-bullhorn text-2xl text-yellow-500"></i>
              </div>
            </div>

            {/* Total Tasks */}
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600">Total Tasks</p>
                  <h3 className="text-2xl font-bold">{metrics.totalTasks}</h3>
                </div>
                <i className="fas fa-tasks text-2xl text-purple-500"></i>
              </div>
            </div>

            {/* Total Pending Tasks */}
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600">Total Pending Tasks</p>
                  <h3 className="text-2xl font-bold">
                    {metrics.totalPendingTasks}
                  </h3>
                </div>
                <i className="fas fa-clock text-2xl text-orange-500"></i>
              </div>
            </div>

            {/* Total Approved Tasks */}
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600">Total Approved Tasks</p>
                  <h3 className="text-2xl font-bold">
                    {metrics.totalApprovedTasks}
                  </h3>
                </div>
                <i className="fas fa-check-circle text-2xl text-green-500"></i>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {/* Example card for Total Ads */}
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Recent Ads
              </h3>
              <div className="space-y-4">
                {ads.map((ad) => (
                  <div
                    key={ad.id}
                    className="flex items-center justify-between bg-gray-50 p-3 rounded-md shadow-sm"
                  >
                    <div>
                      <p className="text-gray-800 font-medium">{ad.title}</p>
                      <p className="text-sm text-gray-600">{ad.status}</p>
                    </div>
                    <i className="fas fa-bullhorn text-yellow-500"></i>
                  </div>
                ))}
              </div>
            </div>

            {/* Total Tasks */}
            <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Recent Tasks
              </h3>
              <div className="space-y-4">
                {tasks.map((task) => (
                  <div
                    key={task.id}
                    className="flex items-center justify-between bg-gray-50 p-3 rounded-md shadow-sm"
                  >
                    <div>
                      <p className="text-gray-800 font-medium">{task.name}</p>
                      <p className="text-sm text-gray-600">{task.status}</p>
                    </div>
                    <i className="fas fa-tasks text-purple-500"></i>
                  </div>
                ))}
              </div>
            </div>

            <div className="lg:col-span-1">
              <div className="bg-white rounded-lg p-6 shadow-sm">
                <h2 className="text-lg font-semibold mb-4">Approved Tasks</h2>
                <div className="space-y-4">
                  {pendingTasks.map((task) => (
                    <div
                      key={task.id}
                      className="hover:bg-gray-50 p-3 rounded-lg border border-gray-100"
                    >
                      <div className="flex justify-between items-start mb-2">
                        <div>
                          <span className="font-medium">{task.user}</span>
                          <p className="text-sm text-gray-600">{task.type}</p>
                        </div>
                        <span className="text-xs bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full">
                          {task.status}
                        </span>
                      </div>
                      <div className="flex justify-between items-center mt-3">
                        <span className="text-xs text-gray-500">
                          {task.timestamp}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-xl font-bold mb-4">Pending Tasks</h2>
            <div className="overflow-x-auto">
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <table className="table-auto w-full bg-white rounded-lg shadow-sm">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="text-left px-4 py-2 text-gray-600">
                        Name
                      </th>
                      <th className="text-left px-4 py-2 text-gray-600">ID</th>
                      <th className="text-left px-4 py-2 text-gray-600">
                        Status
                      </th>
                      <th className="text-left px-4 py-2 text-gray-600">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks.map((task) => (
                      <tr key={task._id} className="border-b hover:bg-gray-50">
                        <td className="px-4 py-2">Task</td>
                        <td className="px-4 py-2">{task.taskId}</td>
                        <td className="px-4 py-2">{task.status}</td>
                        <td className="px-4 py-2">
                          <button
                            className="text-blue-500 hover:underline"
                            onClick={() => handleViewTask(task)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="flex justify-between items-center mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>

            {isModalOpen && selectedTask && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full">
                  <h3 className="text-2xl font-bold mb-6">Task Details</h3>
                  <img
                    src={selectedTask.proof}
                    alt="Proof"
                    className="w-full h-80 object-cover rounded-md mb-6"
                  />
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <p>
                      <strong>Task ID:</strong> {selectedTask.taskId}
                    </p>
                    <p>
                      <strong>Status:</strong> {selectedTask.status}
                    </p>
                    <p className="col-span-2">
                      <strong>Created At:</strong>{" "}
                      {new Date(selectedTask.createdAt).toLocaleString()}
                    </p>
                  </div>
                  <div className="flex justify-end mt-6 gap-4">
                    <button
                      className="bg-red-500 text-white px-6 py-3 rounded-md hover:bg-red-600"
                      onClick={() => {
                        // Reject Task Logic Here
                        console.log("Rejected Task:", selectedTask._id);
                        closeModal();
                      }}
                    >
                      Reject
                    </button>
                    <button
                      className="bg-green-500 text-white px-6 py-3 rounded-md hover:bg-green-600"
                      onClick={() => {
                        // Approve Task Logic Here
                        console.log("Approved Task:", selectedTask._id);
                        closeModal();
                      }}
                    >
                      Approve
                    </button>
                  </div>
                  <button
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                    onClick={closeModal}
                  >
                    ✕
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
